import React from 'react';
import P from 'prop-types';
// components
import injectSheet from 'react-jss';
import Sidebar from './../default/Sidebar/Sidebar';
import Main from './../default/Main';
import {media} from "../defaultPattern";


const defaultStyles = () => ({
  layout: {
    display: 'flex',
    maxWidth: 1124,
    margin: [0, 'auto'],
    padding: [50, 0],
    [`@media (max-width: ${media.mobileWidth})`]: {
      width: '100%',
      maxWidth: '100%',
      flexWrap: 'wrap',
      margin: 0,
      padding: [0, 0],
    },
  },
});

class DefaultLayout extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.layout}>
        <Sidebar />
        <Main />
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  classes: P.object.isRequired,
};

export default injectSheet(defaultStyles)(DefaultLayout);
