import * as Actions from '../actions';
// models

// import PostsModel from 'models/Posts';
// reducer with initial state
const initialState = {
  isLoading: false,
  posts: [],
  post: {},
  error: null,
  meta: {},
};

// https://medium.com/azendoo-team/immutable-record-react-redux-99f389ed676


export default function posts(state = initialState, { type, payload }) {
  switch (type) {
    // POSTS
    case Actions.ActionPosts.type:
      return { ...state, posts: initialState.posts, meta: payload.meta, isLoading: true, error: null };

    case Actions.ActionPosts.success:
      return {
        ...state,
        posts: payload.data,
        meta: {
          currentPage: payload.meta['current-page'],
          page: payload.meta['page'],
          perPage: payload.meta['per-page'],
          total: payload.meta['total'],
        },
        isLoading: false
      };

    case Actions.ActionPosts.failure:
      return { ...state, isLoading: false, error: null };

    // POST
    case Actions.ActionPost.type:
      return { ...state, post: initialState.post, isLoading: true, error: null };

    case Actions.ActionPost.success:
      return { ...state, post: payload.data, isLoading: false, error: null };

    case Actions.ActionPost.failure:
      return { ...state, error: payload.data, isLoading: false };


    default:
      return state;
  }
}
