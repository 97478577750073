import React from 'react';

export const SVGElement = ({ children }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style={{ enableBackground: 'new 0 0 512 512', width: 24, height: 24 }}
    xmlSpace="preserve"
  >
    {children}
  </svg>
);

// social networks https://www.flaticon.com/packs/social-15
export const FacebookSVG = () => (
  <SVGElement>
    <path style={{ fill: '#1976D2' }} d="M448,0H64C28.704,0,0,28.704,0,64v384c0,35.296,28.704,64,64,64h384c35.296,0,64-28.704,64-64V64  C512,28.704,483.296,0,448,0z" />
    <path style={{ fill: '#FAFAFA' }} d="M432,256h-80v-64c0-17.664,14.336-16,32-16h32V96h-64l0,0c-53.024,0-96,42.976-96,96v64h-64v80h64  v176h96V336h48L432,256z" />
  </SVGElement>
);

export const GithubSVG = () => (
  <SVGElement>
    <path
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: '#5C6BC0' }}
      d="M255.968,5.329C114.624,5.329,0,120.401,0,262.353c0,113.536,73.344,209.856,175.104,243.872  c12.8,2.368,17.472-5.568,17.472-12.384c0-6.112-0.224-22.272-0.352-43.712c-71.2,15.52-86.24-34.464-86.24-34.464  c-11.616-29.696-28.416-37.6-28.416-37.6c-23.264-15.936,1.728-15.616,1.728-15.616c25.696,1.824,39.2,26.496,39.2,26.496  c22.848,39.264,59.936,27.936,74.528,21.344c2.304-16.608,8.928-27.936,16.256-34.368c-56.832-6.496-116.608-28.544-116.608-127.008  c0-28.064,9.984-51.008,26.368-68.992c-2.656-6.496-11.424-32.64,2.496-68c0,0,21.504-6.912,70.4,26.336  c20.416-5.696,42.304-8.544,64.096-8.64c21.728,0.128,43.648,2.944,64.096,8.672c48.864-33.248,70.336-26.336,70.336-26.336  c13.952,35.392,5.184,61.504,2.56,68c16.416,17.984,26.304,40.928,26.304,68.992c0,98.72-59.84,120.448-116.864,126.816  c9.184,7.936,17.376,23.616,17.376,47.584c0,34.368-0.32,62.08-0.32,70.496c0,6.88,4.608,14.88,17.6,12.352  C438.72,472.145,512,375.857,512,262.353C512,120.401,397.376,5.329,255.968,5.329z"
    />
  </SVGElement>
);

export const LinkedinSVG = () => (
  <SVGElement>
    <g>
      <rect y="160" style={{ fill: '#1976D2' }} width="114.496" height="352" />
      <path
        style={{ fill: '#1976D2' }}
        d="M426.368,164.128c-1.216-0.384-2.368-0.8-3.648-1.152c-1.536-0.352-3.072-0.64-4.64-0.896   c-6.08-1.216-12.736-2.08-20.544-2.08l0,0l0,0c-66.752,0-109.088,48.544-123.04,67.296V160H160v352h114.496V320   c0,0,86.528-120.512,123.04-32c0,79.008,0,224,0,224H512V274.464C512,221.28,475.552,176.96,426.368,164.128z"
      />
      <circle style={{ fill: '#1976D2' }} cx="56" cy="56" r="56" />
    </g>
  </SVGElement>
);
