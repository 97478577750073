import * as Actions from '../actions';

// reducer with initial state
const initialState = {
  contacts: [],
  isLoading: false,
  errors: [],
};

export default function contacts(state = initialState, { type, payload }) {
  switch (type) {
    case Actions.actionGetContacts.type:
      return { ...state, contacts: initialState.contacts, isLoading: true };
    case Actions.actionGetContacts.success:
      return { ...state, contacts: payload, isLoading: false };
    case Actions.actionGetContacts.failure:
      return { ...state, isLoading: false, errors: payload };
    default:
      return state;
  }
}
