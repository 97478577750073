const rgb = (r, g, b) => `rgb(${r}, ${g}, ${b})`;

const rgba = (r, g, b, a) => `rgb(${r}, ${g}, ${b}, ${a})`;

const fonts = {
  postTitle: "'Source Sans Pro', sans-serif",
  postSubTitle: "'Open Sans', sans-serif",
  postText: "'Open Sans', sans-serif",
  tag: "'Open Sans', sans-serif",
};

const colors = {
  gray: 'gray',
  black: '#000',
  white: rgb(255, 255, 255),
  background: '#fff',
  postsTitle: '#000',
  postsSubtitle: rgba(0, 0, 0, 0.85),
  tagBackground: 'linear-gradient(to bottom, #667db6, #0082c8, #0082c8, #667db6)',
  tagBackgroundHover: 'linear-gradient(to top, #667db6, #0082c8, #0082c8, #667db6)',
};

export default () => ({
  '@font-face': {
    fontFamily: 'Source Sans Pro',
    src: 'url(https://fonts.googleapis.com/css?family=Source+Sans+Pro)',
  },
  colors: { ...colors },
  fonts: { ...fonts },
});

export const media = {
  mobileWidth: '500px',
}