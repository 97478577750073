// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker';
import {CookiesProvider} from "react-cookie";
import {BrowserRouter} from "react-router-dom";
import MainLayout from "./containers/MainLayout";
import {Provider} from "react-redux";
import createSagaMiddleware from "redux-saga";
import {applyMiddleware, compose, createStore} from "redux";
import reducers from "./reducers";
import { sagas } from "./sagas";


const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose ;
export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);
sagaMiddleware.run(sagas);


document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Provider store={store}>
      <CookiesProvider>
        <BrowserRouter>
          <MainLayout />
        </BrowserRouter>
      </CookiesProvider>
    </Provider>,
    document.body.appendChild(document.createElement('div')),
  )
})

serviceWorker.unregister();