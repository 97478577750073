import { combineReducers } from 'redux';

// reducers
import posts from '../reducers/posts';
import contacts from '../reducers/contacts';
import tags from '../reducers/tags';
import categories from '../reducers/categories';

const reducers = combineReducers({
  posts,
  contacts,
  tags,
  categories,
});

export default reducers;
