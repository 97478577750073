import { actionGetTags } from '../actions';

const initialState = {
  tags: [],
  isLoading: false,
  errors: [],
};

export default function tags(state = initialState, { type, payload }) {
  switch (type) {

    case actionGetTags.type:
      return { ...state, tags: initialState.tags, isLoading: true };

    case actionGetTags.success:
      return { ...state, tags: payload.data, isLoading: false };

    case actionGetTags.failure:
      return { ...state, isLoading: false, errors: null };

    default:
      return state;
  }
}
