import React from 'react';
import { Switch, Route } from 'react-router-dom';
// components
import LeftSidebar from './components/LeftSidebar';
// style
import insertSheet from 'react-jss';
import './FacebookLayout.css';
import { routers } from '../../routers';

const facebookLayout = theme => ({
  FacebookLayout: {
    height: '100%',
    background: {
      color: 'rgb(233, 235, 238)',
    },
    '& header': {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      top: 0,
      left: 0,
      width: '100%',
      backgroundColor: 'rgb(66, 103, 178)',
      borderBottom: '1px solid rgb(41, 72, 125)',
      boxSizing: 'border-box',
      color: 'white',
      direction: 'ltr',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontSize: 12,
      height: 43,
      lineHeight: 16,

      '& .wrapper': {
        display: 'flex',
        position: 'relative',
        width: 1012,
        boxSizing: 'border-box',
        fontFamily: 'Helvetica, Arial, sans-serif',
        justifyContent: 'space-between',
        lineHeight: 16.08,
        margin: 0,
        padding: 0,
        color: 'white',
      },
    },

    '& .globalContainer': {
      paddingTop: 43,

      '& .container': {
        display: 'flex',
        width: 1012,
        margin: [0, 'auto'],

        '& .leftSidebar': {
          flex: '0 0 180px',
          marginTop: 10,
        },

        '& .main': {
          flex: 1,
          marginTop: 10,
        },
        '& .rightSidebar': {
          flex: '0 0 308px',
          marginTop: 10,
        },
      },
    },

    '& h4.navHeader': {
      display: 'block',
      padding: [4, 0],
      color: 'rgb(29, 33, 41)',
      fontFamily: 'Helvetica, Arial, sans-serif',
      font: {
        size: 13,
      },

      '& span': {
        color: '#616770',
        font: {
          family: 'Helvetica, Arial, sans-serif',
          size: 13,
          weight: 600,
          height: 17,
        },
      },
    },

    '& ul.navItems': {
      padding: 0,
      listStyle: 'none',
      whiteSpace: 'nowrap',

      '& li': {
        listStyleType: 'none',

        '& a': {
          display: 'flex',
          justifyContent: 'space-between',
          border: '1px solid transparent',
          cursor: 'pointer',

          '&:hover': {
            backgroundColor: '#fefefe',
            border: '1px solid #eee',
            borderRadius: 3,
          },

          '& span': {
            flex: '0 0 20px',
          },

          '& div': {
            flex: 1,
            marginLeft: 2,
          },
        },
      },
    },
  },
});

class FacebookLayout extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={ classes.FacebookLayout }>
        <header>
          <div className="wrapper">
            titar blog
          </div>
        </header>
        <div className="globalContainer">
          <div className="container">
            <LeftSidebar />
            <div className="main">
              <Switch>
                {
                  routers.map(route => (<Route key={route.name} exact path={route.urlFormat} component={route.component} />))
                }
              </Switch>
            </div>
            <div className="rightSidebar">
              rigthSidebar
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default insertSheet(facebookLayout)(FacebookLayout);
