import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import ThemeContext from '../../../configs/theme-context';
import { getUrl } from '../../../routers';
import * as Actions from '../../../actions';
import Logo from '../../../themes/default/face.jpg';
import { FacebookSVG, GithubSVG, LinkedinSVG } from '../../../components/icons';
import {media} from "../../defaultPattern";


const stylesSidebar = theme => ({
  sidebar: {
    flex: '0 0 200px',
    textAlign: 'right',
    marginRight: '2em',
    [`@media (max-width: ${media.mobileWidth})`]: {
      flex: '3 0 100px',
      textAlign: 'left',
      margin: 0,
      padding: '1em'
    },
    '& .sticky-block': {
      position: 'sticky',
      top: 10,
      [`@media (max-width: ${media.mobileWidth})`]: {
        position: 'relative',
        // margin:[0, 'auto']
      },
      '& .logo': {
        marginBottom: '1.5em',
        padding: '0 0 1em',
        textAlign: 'center',
        '& a': {
          '& img': {
            display: 'block',
            width: '80%',
            borderRadius: '50%',
            margin: '0 auto',
          },
        },
      },
      '& .aside': {
        marginBottom: '1.5em',
        '& h4': {
          fontWeight: 700,
          marginBottom: 5,
        },
      },
      '& .aside-contacts': {
        '& ul': {
          padding: 0,
          listStyle: 'none',
          '& li': {
            display: 'inline-flex',
            '& a': {
              textDecoration: 'underline',
              marginLeft: '0.5em',
            },
          },
          '& li:first-child': {
            marginLeft: 0,
            '& a': {
              marginLeft: 0,
            },
          },
        },
      },
      '& .aside-categories': {
        '& span': {
          display: 'inline-flex',
          textDecoration: 'none',
          marginLeft: '0.5em',
        },
      },
      '& .aside-themes': {
        '& span': {
          display: 'inline-flex',
          textDecoration: 'none',
          marginLeft: '0.5em',
        },
      },
      '& .aside-tags': {
        '& ul': {
          padding: 0,
          listStyle: 'none',
          listStyleType: 'none',
          '& li': {
            display: 'inline-flex',
            margin: [0, '0.25em'],
            '& a': {
              textDecoration: 'none',
              color: theme.colors.white,
              padding: [1, 5, 2],
              borderRadius: 5,
              background: {
                color: theme.colors.tagBackground,
              },
              font: {
                family: theme.fonts.postText,
                size: 12,
                weight: 700,
              },
              '&:hover': {
                background: {
                  color: theme.colors.tagBackgroundHover,
                },
              },
            },
          },
        },
      },
    }
  },
});

class Sidebar extends PureComponent {
  componentDidMount() {
    const { fetchTags, fetchCategories } = this.props;
    // const { fetchContacts } = this.props;
    // fetchContacts();
    fetchTags();
    fetchCategories();
  }

  render() {
    const {
      classes, contacts, tags, categories,
    } = this.props;

    return (
      <aside className={classes.sidebar}>
        <div className="sticky-block">
          <div className="logo">
            <Link to={{ pathname: getUrl('Index') }}>
              <img src={Logo} alt="" />
            </Link>
          </div>

          <div className="aside aside-contacts">
            <ul>
              {
                contacts.map(({ title, icon, url }) => (
                  <li key={title}>
                    <Link to={{ pathname: url }} target="_blank">
                      {icon}
                    </Link>
                  </li>))
              }
            </ul>
          </div>

          {
            categories.length
              ? (
                <div className="aside aside-categories">
                  <h4>categories</h4>
                  {
                    categories.map(c => (
                      <Link
                            key={c?.attributes?.name}
                            to={{ pathname: getUrl('Category', { categoryId: c?.attributes?.name }) }}
                      >
                        <span>
                          { c?.attributes?.name }
                        </span>
                      </Link>
                    ))
                  }
                </div>
                )
              : ''
          }


          {/*<div className="aside aside-themes">*/}
          {/*  <h4>themes</h4>*/}
          {/*  <ThemeContext.Consumer>*/}
          {/*    {({ themes, toggleTheme }) => themes.map(({ title, theme }) => (*/}
          {/*      <span key={title} onClick={toggleTheme(theme)}>*/}
          {/*        {title}*/}
          {/*      </span>*/}
          {/*    ))}*/}
          {/*  </ThemeContext.Consumer>*/}
          {/*</div>*/}


          {
            tags.length
              ? (
                <div className="aside aside-tags">
                  <h4>tags</h4>
                  <ul>
                    {
                      tags.map(tag => (
                        <li key={tag?.attributes?.name}>
                          <Link to={{ pathname: getUrl('Tag', { tagId: tag?.attributes?.name }) }}>
                            {tag?.attributes?.name} ({ tag?.attributes?.count })
                          </Link>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              )
              : ''
          }

        </div>
      </aside>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object,
  contacts: PropTypes.array,
  tags: PropTypes.any,
  categories: PropTypes.any,
  // fetchContacts: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
  contacts: [
    {
      title: 'facebook',
      icon: <FacebookSVG />,
      url: 'https://www.facebook.com/vitaliy.titarenko',
    },
    {
      title: 'github',
      icon: <GithubSVG />,
      url: 'https://github.com/ti-tar',
    },
    {
      title: 'linkedin',
      icon: <LinkedinSVG />,
      url: 'https://www.linkedin.com/in/vitaliy-titarenko-44a9a839/',
    },
  ],
  tags: [],
  categories: [],
};

export default compose(
  connect(
    state => ({
      // contacts: state.contacts.contacts,
      tags: state.tags.tags,
      categories: state.categories.categories,
    }),
    {
      // fetchContacts: () => ({ type: Actions.actionGetContacts.type }),
      fetchTags: () => ({ type: Actions.actionGetTags.type }),
      fetchCategories: () => ({ type: Actions.actionCategories.type }),
    },
  ),
  injectSheet(stylesSidebar),
)(Sidebar);
