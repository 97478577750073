import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import MarkdownRenderer from 'react-markdown-renderer';
import {Link, withRouter} from 'react-router-dom';
import injectSheet from 'react-jss';

import { ActionPost } from '../../../actions';
import markdownStyles from '../../default/Posts/markdownStyles';
import {getUrl} from "../../../routers";
import {media} from "../../defaultPattern";

const stylesPost = theme => ({
  post: {
    width: 900,
    maxWidth: 900,
    wordWrap: "break-word",
    [`@media (max-width: ${media.mobileWidth})`]: {
      width: '100%',
      maxWidth: '100%',
    },
    '& .category-tags': {
      margin: ['0.5em', 0],
      '& .category': {
        marginRight: '1.5em',
        fontSize: 15
      },
      '& .tags': {
        textDecoration: 'none',
        color: theme.colors.white,
        padding: [1, 5, 2],
        borderRadius: 5,
        marginRight: 5,
        background: {
          color: theme.colors.tagBackground,
        },
        font: {
          family: theme.fonts.postText,
          size: 12,
          weight: 700,
        },
        '&:hover': {
          background: {
            color: theme.colors.tagBackgroundHover,
          },
        },
        '& span': {
          display: 'inline-flex',
          margin: [0, '0.25em'],
        },
      },
    },
    '& .title': {
      margin: 0,
      font: {
        family: theme.fonts.postTitle,
        size: 32,
        weight: 400,
      },
    },
    "& .introtext": {
      margin: '1em 0',
      font: {
        family: theme.fonts.postSubTitle,
        size: 16,
        weight: 200,
      },
    },
  },


  image: {
    margin: '1em 0',
    '& img': {
      maxWidth: '100%'
    }
  },
  text: {

  },
  markdown: {
    margin: 0,
    font: {
      family: theme.fonts.postText,
      size: 14,
      weight: 200,
    },
    lineHeight: 1.4,
    ...markdownStyles,
  },
});

class Post extends Component {
  componentDidMount() {
    const { fetchPost, match: { params } } = this.props;
    fetchPost(params.postId);
  }

  render() {
    const { classes, post } = this.props;

    return (
      <section className={classes.post}>
        {
          post?.attributes?.title
            ? (
              <>
                <h1 className="title">{post?.attributes?.title}</h1>

                <div className="category-tags">
                  { !!post?.attributes?.category && (
                      <Link
                          to={{ pathname: getUrl('Category', { categoryId: post?.attributes?.category?.name }) }}
                          key={post?.attributes?.category?.name}
                          className="category"
                      >
                        <span>
                          {post?.attributes?.category?.name}
                        </span>
                      </Link>
                  )}
                  {
                    (post?.attributes?.tags || []).map(tag => (
                        <Link
                            to={{ pathname: getUrl('Tag', { tagId: tag.name }) }}
                            className="tags"
                            key={tag.name}
                        >
                        <span>
                          {tag.name}
                        </span>
                        </Link>
                    ))
                  }
                </div>

                { post?.attributes?.image?.large?.url
                    ? <div className={classes.image}>
                      <img src={ post.attributes.image.large.url } alt={''}  />
                    </div>
                    : ''
                }

                <h3 className="introtext">{post?.attributes?.introtext}</h3>

                <div className={classes.markdown}>
                  <MarkdownRenderer markdown={post?.attributes?.text || ''} />
                </div>
              </>
            )
            : 'There`s no such post.'
        }
      </section>
    );
  }
}


export default compose(
  connect(
    state => ({
      post: state.posts.post,
    }),
    {
      fetchPost: postId => ({ type: ActionPost.type, payload: postId }),
    },
  ),
  withRouter,
  injectSheet(stylesPost),
)(Post);
