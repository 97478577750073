import * as Actions from '../actions';

const initialState = {
  categories: [],
  isLoading: false,
  error: null,
};

export default function categories(state = initialState, { type, payload }) {
  switch (type) {
    case Actions.actionCategories.type:
      return { ...state, categories: initialState.categories, isLoading: true, error: null };

    case Actions.actionCategories.success:
      return { ...state, categories: payload.data, isLoading: false };

    case Actions.actionCategories.failure:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
};
