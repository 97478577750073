import React, { Component } from 'react';
import PropTypes, {number} from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
// actions
import { ActionPosts } from '../../../actions';
import { getUrl } from '../../../routers';
import {media} from "../../defaultPattern";


const stylesPosts = theme => ({
  posts: {
    display: 'block',
    maxWidth: 900,
    wordWrap: "break-word",
    [`@media (max-width: ${media.mobileWidth})`]: {
      maxWidth: '100%',
    },
    '& > .filter-tag, & > .filter-category': {
      marginBottom: '2em',
      '& small': {
        fontFamily: 'Helvetica, Arial, sans-serif',
        color: 'gray'
      },
      '& h1': {
        fontSize: 36
      }
    },
  },
  postItem: {
    margin: [0, 0, '3em', 0],

    '& .link': {
      textDecoration: 'none',
      '& .title': {
        font: {
          family: theme.fonts.postTitle,
          size: 24,
          weight: 400,
        },
        margin: [0, 0, '0.25em', 0],
        color: theme.colors.black,
      },
      '&:hover .title': {
        textDecoration: 'underline',
      },
      '& .introtext': {
        margin: ['1em', 0],
        font: {
          family: theme.fonts.postSubTitle,
          size: 14,
          weight: 200,
        },
        color: theme.colors.postsSubtitle,
      }
    },
    '& .category-tags': {
      margin: 0,
      '& .category': {
        marginRight: '1.5em',
        fontSize: 15
      },
      '& .tags': {
        textDecoration: 'none',
        color: theme.colors.white,
        padding: [1, 5, 2],
        borderRadius: 5,
        marginRight: 5,
        background: {
          color: theme.colors.tagBackground,
        },
        font: {
          family: theme.fonts.postText,
          size: 12,
          weight: 700,
        },
        '&:hover': {
          background: {
            color: theme.colors.tagBackgroundHover,
          },
        },
        '& span': {
          display: 'inline-flex',
          margin: [0, '0.25em'],
        },
      },
    },
    '& .image': {
      margin: '0.5em 0',
      '& img': {
        maxWidth: '100%'
      }
    },
  },
  pagination: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    [`@media (max-width: ${media.mobileWidth})`]: {
      justifyContent: 'center',
      marginBottom: '1em',
    },
    '& ul': {
      display: 'flex',
      listStyle: 'none',
      justifyContent: 'flex-end',
      '& li': {
        display: 'inline-flex',
        marginLeft: '1em',
        padding: 5,
        '&.active': {
          background: theme.colors.tagBackground,
          '& a': {
            color: theme.colors.white,
          },
        },
      },
    },
  },
});

class Posts extends Component {
  componentDidMount() {
    this.getPosts();
  }

  componentDidUpdate(prevProps) {
    const { match, location } = this.props;

    if (prevProps.match !== match && prevProps.location.search !== location.search) {
      this.getPosts();
    }

    if (match.params.tagId && prevProps.match.params.tagId !== match.params.tagId) {
      this.getPosts();
    }

    if (match.params.categoryId && prevProps.match.params.categoryId !== match.params.categoryId) {
      this.getPosts();
    }
  }

  getPosts() {
    const { fetchPosts, match, location } = this.props;
    let url;
    if (match.params.tagId) {
      url = `tags/${match.params.tagId}`;
    } else if (match.params.categoryId) {
      url = `categories/${match.params.categoryId}`;
    } else {
      url = 'posts';
    }
    fetchPosts(url, location.search);
  }

  initPageNumbers = () => {
    const { meta } = this.props;
    const total = parseInt(meta.total, 10);
    let page = 1;
    const rows = [];
    for (let x = 0; x < total; x += meta.perPage) {
      rows.push(page);
      page += 1;
    }
    return rows;
  }

  pagination = () => {
    const { match, meta, classes } = this.props;
    if (!meta?.total) {
      return null;
    }

    const rows = this.initPageNumbers();
    let currentRoute;
    if (match.params.tagId) {
      currentRoute = getUrl('Tag', match.params);
    } else if (match.params.categoryId) {
      currentRoute = getUrl('Category', match.params);
    } else {
      currentRoute = getUrl('Posts', match.params);
    }

    return meta.total >= meta.perPage && (
      <div className={classes.pagination}>
        <ul>
          {rows.map((r, i) => (
            <li key={r} className={meta.currentPage === i + 1 ? 'active' : ''}>
              <Link to={{ pathname: currentRoute, search: `?page=${r}` }}>{r}</Link>
            </li>
          )) }
        </ul>
      </div>
    );
  }

  render() {
    const { classes, posts, match } = this.props;

    return (
      <section className={classes.posts}>

        { match.params.tagId ? (
            <div className="filter-tag">
              <small>tag</small>
              <h1>{match.params.tagId}</h1>
            </div>
        ) : '' }
        { match.params.categoryId ? (
            <div className="filter-category">
              <small>category</small>
              <h1>{match.params.categoryId}</h1>
            </div>
        ) : '' }

        { this.pagination() }

        {
          posts.length > 0
            && posts.map(post => (
              <div className={classes.postItem} key={post?.attributes?.slug}>
                <Link className="link" to={{ pathname: `/posts/${post?.attributes?.slug}` }}>
                  <h2 className="title">
                    {post?.attributes?.title}
                  </h2>
                </Link>

                <div className="category-tags">
                  { !!post?.attributes?.category && (
                      <Link
                          to={{ pathname: getUrl('Category', { categoryId: post?.attributes?.category?.name }) }}
                          key={post?.attributes?.category?.name}
                          className="category"
                      >
                        <span>
                          {post?.attributes?.category?.name}
                        </span>
                      </Link>
                  )}
                  {
                    (post?.attributes?.tags || []).map(tag => (
                        <Link
                            to={{ pathname: getUrl('Tag', { tagId: tag.name }) }}
                            className="tags"
                            key={tag.name}
                        >
                        <span>
                          {tag.name}
                        </span>
                        </Link>
                    ))
                  }
                </div>

                <Link className="link" to={{ pathname: `/posts/${post?.attributes?.slug}` }}>
                  <div className="introtext">
                    {post?.attributes?.introtext}
                  </div>
                </Link>

                { post?.attributes?.image?.preview?.url
                    ? <div className="image">
                        <img src={ post.attributes.image.preview.url } alt={''}  />
                    </div>
                    : ''
                }

              </div>
            ))
        }

        { this.pagination() }

      </section>
    );
  }
}

Posts.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fetchPosts: PropTypes.func.isRequired,
  posts: PropTypes.array,
  meta: PropTypes.object,
};

Posts.defaultProps = {
  posts: {

    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      introtext: PropTypes.string,
      category: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        created_at: PropTypes.string,
        updated_at: PropTypes.string
      }),
      tags: PropTypes.array,
      image: PropTypes.shape({
        large: PropTypes.shape({
          url: PropTypes.string,
        }),
        preview: PropTypes.shape({
          url: PropTypes.string,
        }),
        thumb: PropTypes.shape({
          url: PropTypes.string,
        }),
        url: PropTypes.string,
      })
    })

  },
  meta: {},
};

export default compose(
  connect(
    state => ({
      posts: state.posts.posts,
      meta: state.posts.meta,
    }),
    {
      fetchPosts: (url, page) => ({ type: ActionPosts.type, payload: { url, page } }),
    },
  ),
  injectSheet(stylesPosts),
)(Posts);
