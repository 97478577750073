import React from 'react';

import { withCookies } from 'react-cookie';
import { compose } from 'redux';

import DefaultLayout from './../themes/default/index';
import FacebookLayout from './../themes/facebook/index';

// jss
import { ThemeProvider } from 'react-jss';
import themeStyles from '../themes/defaultPattern';
import ThemeContext from '../configs/theme-context';

class MainLayout extends React.Component {
  themes = [
    {
      title: 'default',
      theme: 'default',
      componentClassName: 'DefaultLayout',
      component: <DefaultLayout />,
    },
    {
      title: 'facebook',
      theme: 'facebook',
      componentClassName: 'FacebookLayout',
      component: <FacebookLayout />,
    },
  ];

  constructor(props) {
    super(props);
    this.state = {};

    const { cookies } = props;
    if (!cookies.get('theme')) {
      cookies.set('theme', 'default');
    }
  }

  toggleTheme = theme => () => {
    if (window.confirm(`Wanna set '${theme}' theme?`)) {
      const { cookies } = this.props;
      cookies.set('theme', theme);
      const currentTheme = this.themes.find(item => item.theme === theme);

      // for css encapsulation set root div like <div id="app" class="ThemeLayout">
      document.getElementById('app').className = currentTheme ? currentTheme.componentClassName : '';
      // console.log(`${theme} theme has been set`);
    }
  }

  render() {
    const { cookies } = this.props;

    return (
      <ThemeProvider theme={themeStyles}>
        <ThemeContext.Provider value={{ themes: this.themes, toggleTheme: this.toggleTheme }}>

          {this.themes.find(item => item.theme === cookies.get('theme'))
            ? this.themes.find(item => item.theme === cookies.get('theme')).component
            : this.themes.find(item => item.theme === 'default').component }

        </ThemeContext.Provider>
      </ThemeProvider>
    );
  }
}

export default compose(
  withCookies,
)(MainLayout);
