import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import injectSheet from 'react-jss';

import { routers } from '../../routers';
import {media} from "../defaultPattern";


const stylesMain = () => ({
  main: {
    flexGrow: 0,
    [`@media (max-width: ${media.mobileWidth})`]: {
      flex: '100%',
      marginTop: '3em',
      padding: '1em',
    },
  },
});

class Main extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.main}>
        <Switch>
          {
            routers.map(route => (<Route key={route.name} exact path={route.urlFormat} component={route.component} />))
          }
        </Switch>
      </main>
    );
  }
}


Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(stylesMain)(Main);
