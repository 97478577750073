import {
  takeEvery, put, fork,
} from 'redux-saga/effects';
import axios from 'axios';
//
import * as actions from './actions';

function* fetchPosts({ payload }) {
  try {
    const { data } = yield axios(`/api/v1/${payload.url}${payload.page || '' }`);
    yield put({ type: actions.ActionPosts.success, payload: data });
  } catch (errors) {
    yield put({ type: actions.ActionPosts.failure, payload: errors });
  }
}

function* fetchPost({ payload }) {
  try {
    const { data } = yield axios(`/api/v1/posts/${payload}`);
    yield put({ type: actions.ActionPost.success, payload: data });
  } catch (errors) {
    yield put({ type: actions.ActionPost.failure, payload: errors });
  }
}

/*
function* fetchContacts() {
  try {
    const { data } = yield axios('/api/v1/');

    // yield put({ type: Actions.ActionPost.success, payload: data });
  } catch (errors) {
    // yield put({ type: Actions.ActionPost.failure, payload: errors });
  }
}
*/

function* fetchTags() {
  try {
    const { data } = yield axios('/api/v1/tags');
    yield put({ type: actions.actionGetTags.success, payload: data });
  } catch (errors) {
    yield put({ type: actions.actionGetTags.failure, payload: errors });
  }
}

function* fetchCategory() {
  try {
    const { data } = yield axios('/api/v1/categories');
    yield put({ type: actions.actionCategories.success, payload: data });
  } catch (errors) {
    yield put({ type: actions.actionCategories.failure, payload: errors });
  }
}


function* components() {
  yield takeEvery(actions.ActionPosts.type, fetchPosts);
  yield takeEvery(actions.ActionPost.type, fetchPost);

  // yield takeEvery(Actions.actionGetContacts.type, fetchContacts);

  yield takeEvery(actions.actionGetTags.type, fetchTags);

  yield takeEvery(actions.actionCategories.type, fetchCategory);
}

export function* sagas() {
  yield fork(components);
}
