import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ThemeContext from '../../../configs/theme-context';


class LeftSidebar extends React.PureComponent {
  render() {
    // const { contacts } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ themes, toggleTheme }) => (
          <div className="leftSidebar">
            <section>
              <h4 className="navHeader">
                <span>Themes</span>
              </h4>
              <ul className="navItems">
                {
                  themes.map(({ title, theme }, index) => (
                    <li key={index}>
                      <a onClick={toggleTheme(theme)}>
                        <span>
                          <img className="img" src="https://static.xx.fbcdn.net/rsrc.php/v3/yh/r/yH0cSMd8mn8.png" draggable="false" alt="" width="20" height="20" />
                        </span>
                        <div>{title}</div>
                      </a>
                    </li>
                  ))
                }
              </ul>
            </section>
            <section>
              <h4 className="navHeader">
                <span>Contacts</span>
              </h4>
              <ul className="navItems">
                { /*
                  contacts.map(({title, url}, index )=> (
                    <li key={index}>
                      <Link to={url} target="_blank">
                          <span>
                            <img className="img" src="https://static.xx.fbcdn.net/rsrc.php/v3/yh/r/yH0cSMd8mn8.png" draggable="false" alt="" width="20" height="20" />
                          </span>
                        <div>{title}</div>
                      </Link>
                    </li>
                  )) */
                }
              </ul>
            </section>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

LeftSidebar.propTypes = {
  connect: PropTypes.array,
};

export default connect(state => ({
  contacts: state.contacts,
}))(LeftSidebar);
