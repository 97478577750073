import Posts from './themes/default/Posts/Posts';
import Post from './themes/default/Posts/Post';

export const routers = [
  {
    name: 'Index',
    urlFormat: '/',
    exact: true,
    component: Posts,
  },
  {
    name: 'Post',
    urlFormat: '/posts/:postId',
    exact: true,
    component: Post,
  },
  {
    name: 'Posts',
    urlFormat: '/posts',
    exact: true,
    component: Posts,
  },
  {
    name: 'Tag',
    urlFormat: '/tags/:tagId',
    exact: true,
    component: Posts,
  },
  {
    name: 'Category',
    urlFormat: '/categories/:categoryId',
    exact: true,
    component: Posts,
  },
];

export const getUrl = (name, params = {}) => {
  const router = routers.find(r => r.name === name);
  if (!router) {
    return '/';
  }
  let newUrlFormat = router.urlFormat;
  Object.keys(params).forEach((paramKey) => {
    newUrlFormat = newUrlFormat.replace(`:${paramKey}`, params[paramKey]);
  });
  return newUrlFormat;
};
