export const getAction = base => ({
  type: `${base}`,
  success: `${base}-success`,
  failure: `${base}-failure`,
});

export const ActionPosts = getAction('posts/fetch-posts');
export const ActionPost = getAction('posts/fetch-post');

export const actionGetContacts = getAction('contacts/fetch-contacts');

export const actionGetTags = getAction('contacts/fetch-contacts');

export const actionCategories = getAction('categories/fetch-categories');