export default {
  '& body': {
    MsTextSizeAdjust: '100%',
    WebkitTextSizeAdjust: '100%',
    lineHeight: '1.5',
    color: '#24292e',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontSize: '16px',
    wordWrap: 'break-word',
  },
  '& body_____first_child': {
    marginTop: '0 !important',
  },
  '& body_____last_child': {
    marginBottom: '0 !important',
  },
  '& a': {
    color: '#4183C4',
    textDecoration: 'none',
  },
  '& a_absent': {
    color: '#cc0000',
  },
  '& a_anchor': {
    display: 'block',
    paddingLeft: '30px',
    marginLeft: '-30px',
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
  },
  '& h1,& h2,& h3,& h4,& h5,& h6': {
    marginTop: '1em',
  },
  '& h1': {
    fontSize: '28px',
    color: 'black',
  },
  '& h2': {
    fontSize: '24px',
    borderBottom: '1px solid #cccccc',
    color: 'black',
  },
  '& h3': {
    fontSize: '18px',
  },
  '& h4': {
    fontSize: '16px',
  },
  '& h5': {
    fontSize: '14px',
  },
  '& h6': {
    color: '#777777',
    fontSize: '14px',
  },
  '& h2_first_child': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& h1_first_child': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& h1_first_child___h2': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& h3_first_child': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& h4_first_child': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& h5_first_child': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& h6_first_child': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& h1_hover_a_anchor': {
    textDecoration: 'none',
  },
  '& h2_hover_a_anchor': {
    textDecoration: 'none',
  },
  '& h3_hover_a_anchor': {
    textDecoration: 'none',
  },
  '& h4_hover_a_anchor': {
    textDecoration: 'none',
  },
  '& h5_hover_a_anchor': {
    textDecoration: 'none',
  },
  '& h6_hover_a_anchor': {
    textDecoration: 'none',
  },
  '& h1_tt': {
    fontSize: 'inherit',
  },
  '& h1_code': {
    fontSize: 'inherit',
  },
  '& h2_tt': {
    fontSize: 'inherit',
  },
  '& h2_code': {
    fontSize: 'inherit',
  },
  '& h3_tt': {
    fontSize: 'inherit',
  },
  '& h3_code': {
    fontSize: 'inherit',
  },
  '& h4_tt': {
    fontSize: 'inherit',
  },
  '& h4_code': {
    fontSize: 'inherit',
  },
  '& h5_tt': {
    fontSize: 'inherit',
  },
  '& h5_code': {
    fontSize: 'inherit',
  },
  '& h6_tt': {
    fontSize: 'inherit',
  },
  '& h6_code': {
    fontSize: 'inherit',
  },
  '& p': {
    margin: '15px 0',
  },
  '& blockquote': {
    borderLeft: '4px solid #dddddd',
    padding: '0 15px',
    color: '#777777',
  },
  '& ul': {
    paddingLeft: '30px',
  },
  '& ol': {
    paddingLeft: '30px',
  },
  '& dl': {
    padding: '0',
  },
  '& li': {
    margin: '15px 0',
  },
  '& table': {
    padding: '0',
  },
  '& pre': {
    display: 'block',
    maxWidth: '100%',
    overflowX: 'auto',
    backgroundColor: '#f8f8f8',
    border: '1px solid rgb(234, 234, 234)',
    fontSize: '13px',
    lineHeight: '19px',
    overflow: 'auto',
    padding: '6px 10px',
    borderRadius: '3px',
  },
  '& hr': {
    background: 'transparent url("http://tinyurl.com/bq5kskr") repeat-x 0 0',
    border: '0 none',
    color: '#cccccc',
    height: '4px',
    padding: '0',
  },
  '& body___h2_first_child': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& body___h1_first_child': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& body___h1_first_child___h2': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& body___h3_first_child': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& body___h4_first_child': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& body___h5_first_child': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& body___h6_first_child': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& a_first_child_h1': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& a_first_child_h2': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& a_first_child_h3': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& a_first_child_h4': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& a_first_child_h5': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& a_first_child_h6': {
    marginTop: '0',
    paddingTop: '0',
  },
  '& h1_p': {
    marginTop: '0',
  },
  '& h2_p': {
    marginTop: '0',
  },
  '& h3_p': {
    marginTop: '0',
  },
  '& h4_p': {
    marginTop: '0',
  },
  '& h5_p': {
    marginTop: '0',
  },
  '& h6_p': {
    marginTop: '0',
  },
  '& li_p_first': {
    display: 'inline-block',
  },
  '& ul__first_child': {
    marginTop: '0',
  },
  '& ol__first_child': {
    marginTop: '0',
  },
  '& ul__last_child': {
    marginBottom: '0',
  },
  '& ol__last_child': {
    marginBottom: '0',
  },
  '& dl_dt': {
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'italic',
    padding: '0',
    margin: '15px 0 5px',
  },
  '& dl_dt_first_child': {
    padding: '0',
  },
  '& dl_dt____first_child': {
    marginTop: '0',
  },
  '& dl_dt____last_child': {
    marginBottom: '0',
  },
  '& dl_dd': {
    margin: '0 0 15px',
    padding: '0 15px',
  },
  '& dl_dd____first_child': {
    marginTop: '0',
  },
  '& dl_dd____last_child': {
    marginBottom: '0',
  },
  '& blockquote____first_child': {
    marginTop: '0',
  },
  '& blockquote____last_child': {
    marginBottom: '0',
  },
  '& table_tr': {
    borderTop: '1px solid #cccccc',
    backgroundColor: 'white',
    margin: '0',
    padding: '0',
  },
  '& table_tr_nth_child_2n': {
    backgroundColor: '#f8f8f8',
  },
  '& table_tr_th': {
    fontWeight: 'bold',
    border: '1px solid #cccccc',
    textAlign: 'left',
    margin: '0',
    padding: '6px 13px',
  },
  '& table_tr_td': {
    border: '1px solid #cccccc',
    textAlign: 'left',
    margin: '0',
    padding: '6px 13px',
  },
  '& table_tr_th__first_child': {
    marginTop: '0',
  },
  '& table_tr_td__first_child': {
    marginTop: '0',
  },
  '& table_tr_th__last_child': {
    marginBottom: '0',
  },
  '& table_tr_td__last_child': {
    marginBottom: '0',
  },
  '& img': {
    maxWidth: '100%',
  },
  '& span_frame': {
    display: 'block',
    overflow: 'hidden',
  },
  '& span_frame___span': {
    border: '1px solid #dddddd',
    display: 'block',
    float: 'left',
    overflow: 'hidden',
    margin: '13px 0 0',
    padding: '7px',
    width: 'auto',
  },
  '& span_frame_span_img': {
    display: 'block',
    float: 'left',
  },
  '& span_frame_span_span': {
    clear: 'both',
    color: '#333333',
    display: 'block',
    padding: '5px 0 0',
  },
  '& span_align_center': {
    display: 'block',
    overflow: 'hidden',
    clear: 'both',
  },
  '& span_align_center___span': {
    display: 'block',
    overflow: 'hidden',
    margin: '13px auto 0',
    textAlign: 'center',
  },
  '& span_align_center_span_img': {
    margin: '0 auto',
    textAlign: 'center',
  },
  '& span_align_right': {
    display: 'block',
    overflow: 'hidden',
    clear: 'both',
  },
  '& span_align_right___span': {
    display: 'block',
    overflow: 'hidden',
    margin: '13px 0 0',
    textAlign: 'right',
  },
  '& span_align_right_span_img': {
    margin: '0',
    textAlign: 'right',
  },
  '& span_float_left': {
    display: 'block',
    marginRight: '13px',
    overflow: 'hidden',
    float: 'left',
  },
  '& span_float_left_span': {
    margin: '13px 0 0',
  },
  '& span_float_right': {
    display: 'block',
    marginLeft: '13px',
    overflow: 'hidden',
    float: 'right',
  },
  '& span_float_right___span': {
    display: 'block',
    overflow: 'hidden',
    margin: '13px auto 0',
    textAlign: 'right',
  },
  '& code': {
    margin: '0 2px',
    padding: '0 5px',
    // whiteSpace: 'nowrap',
    border: '1px solid #eaeaea',
    backgroundColor: '#f8f8f8',
    borderRadius: '3px',
    whiteSpace: 'pre-wrap',
  },
  '& tt': {
    margin: '0 2px',
    padding: '0 5px',
    whiteSpace: 'nowrap',
    border: '1px solid #eaeaea',
    backgroundColor: '#f8f8f8',
    borderRadius: '3px',
  },
  '& pre code': {
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
    border: 'none',
  },
  '& highlight_pre': {
    backgroundColor: '#f8f8f8',
    border: '1px solid #cccccc',
    fontSize: '13px',
    lineHeight: '19px',
    overflow: 'auto',
    padding: '6px 10px',
    borderRadius: '3px',
  },
  '& pre_tt': {
    backgroundColor: 'transparent',
    border: 'none',
  },
};
